import NextHead from 'next/head'
import useTranslation from 'next-translate/useTranslation'

import isProduction from '../../app/utils/isAppProduction'
import getImageUrl from '../../app/utils/getImageUrl'

type HeadProps = {
  title?: string
  description?: string
  imageId?: string | null
  noIndex?: boolean
}

function Head({ title, description, imageId, noIndex }: HeadProps) {
  const { t } = useTranslation('generated')

  return (
    <NextHead>
      <title>{title || t('brandName')}</title>
      <meta
        name="description"
        content={description || t('brandDescription')}
        data-testid="meta-description"
      />
      {/* Open Graph */}
      <meta property="og:title" content={title || t('brandName')} />
      <meta
        property="og:description"
        content={description || t('brandDescription')}
      />
      {imageId && <meta property="og:image" content={getImageUrl(imageId)} />}
      {(!isProduction() || noIndex) && (
        <meta name="robots" content="noindex,nofollow" />
      )}
    </NextHead>
  )
}

export default Head
