import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'

import Head from './Head'
import styles from './Page.module.scss'

type PageProps = PropsWithChildren<{
  title?: string
  description?: string
  className?: string
  imageId?: string | null
  noIndex?: boolean
}>

export default function Page({
  title,
  description,
  children,
  imageId,
  className,
  noIndex = false,
}: PageProps) {
  const { t } = useTranslation('generated')

  return (
    <>
      <Head
        title={title ? `${title} - ${t('brandName')}` : t('brandName')}
        description={description}
        imageId={imageId}
        noIndex={noIndex}
      />
      <main className={clsx(styles.mainContainer, className)}>{children}</main>
    </>
  )
}
