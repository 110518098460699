import { PropsWithChildren, useRef } from 'react'
import clsx from 'clsx'
import Image from 'next/image'

import styles from './LandingBackground.module.scss'

type LandingBackgroundProps = PropsWithChildren<{
  className?: string
}>

function getRandomBackgroundImage() {
  const currentHour = new Date().getHours()
  const imageIndex = currentHour % 3
  return `/images/landing-bg-${imageIndex}.jpg`
}

export default function LandingBackground({
  children,
  className,
}: LandingBackgroundProps) {
  const backgroundImage = useRef(getRandomBackgroundImage())

  return (
    <div className={clsx(styles.container, className)}>
      <Image
        fill
        priority
        alt="background image"
        src={backgroundImage.current}
      />
      {children}
    </div>
  )
}
