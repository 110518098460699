import { forwardRef } from 'react'
import { useRouter } from 'next/router'
import { batch } from 'react-redux'
import { CustomerAddress } from '@ancon/wildcat-types'
import useTranslation from 'next-translate/useTranslation'

import { locationSetCurrentGeoPositionName } from '../../../location/store/locationSlice'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  outletsSetOutletsFilters,
  outletsSetVisibleOutletSearchModal,
} from '../../store/outletsSlice'
import { AppRoutes } from '../../../app/constants'
import { OutletSearchModalType } from '../../types'

import styles from './OutletSearchResultsOverlay.module.scss'
import OutletSearchSuggestions from './suggestions/OutletSearchSuggestions'
import CurrentLocationSuggestion from './suggestions/CurrentLocationSuggestion'
import AccountAddressSuggestions from './suggestions/AccountAddressSuggestions'

type OutletSearchResultsProps = {
  query: string
}

function OutletSearchResultsOverlay(
  { query }: OutletSearchResultsProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const router = useRouter()

  function handleNavigateToNearByRestaurants(
    geocodeResult: google.maps.GeocoderResult,
  ) {
    batch(() => {
      dispatch(
        locationSetCurrentGeoPositionName(geocodeResult.formatted_address),
      )
      dispatch(
        outletsSetOutletsFilters({
          searchTerm: undefined,
        }),
      )
      dispatch(outletsSetVisibleOutletSearchModal(OutletSearchModalType.None))
    })
    router.push({
      pathname: AppRoutes.Outlets,
      query: {
        pl: geocodeResult.place_id,
      },
    })
  }

  function handleNavigateToNearByRestaurantsOfAddress(
    address: CustomerAddress,
    geocodeResult: google.maps.GeocoderResult,
  ) {
    batch(() => {
      dispatch(
        locationSetCurrentGeoPositionName(geocodeResult.formatted_address),
      )
      dispatch(
        outletsSetOutletsFilters({
          searchTerm: undefined,
        }),
      )
      dispatch(outletsSetVisibleOutletSearchModal(OutletSearchModalType.None))
    })
    router.push({
      pathname: AppRoutes.Outlets,
      query: {
        pl: geocodeResult.place_id,
      },
    })
  }

  return (
    <div ref={ref} className={styles.container}>
      {!query && (
        <CurrentLocationSuggestion
          label={t('components.outletSearchModal.nearbyRestaurants')}
          onClick={handleNavigateToNearByRestaurants}
        />
      )}
      {!query && (
        <AccountAddressSuggestions
          onAddressClick={handleNavigateToNearByRestaurantsOfAddress}
        />
      )}
      {query && <OutletSearchSuggestions searchTerm={query} />}
    </div>
  )
}

export default forwardRef(OutletSearchResultsOverlay)
